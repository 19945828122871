import React, { useEffect, useRef  } from "react";
import { useSetState, useRequest } from "ahooks";
import { GAME_SCENES } from "@/babylon/scenes";

import './LocationPanel.scss';
import { Input, Toast, Swiper } from "antd-mobile";
import { ArrowLeftOutlined, DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import classNames from "classnames";
import messageApi from "@/shared/api/messageApi";
import readerApi from "@/shared/api/readerApi";

export default function LocationPanel(props) {

  const [state, setState] = useSetState({
    activeLocation: props.activeLocation || 'main',
    activeCityNum : props.activeCityNum || '',
    selectCityNum: props.activeCityNum || '',
    step: 0,
    swiperIndex: 0
  })
  const SwiperRef = useRef(null)
  const onClose=()=>{
    props.onClose();
    setState({
      step: 0,
      swiperIndex: 0
    })
  }

  const cityNumDom = (num) => (
    <div
      key={num}
      className={activeCityNum === num ? 'cityNum activeCityNum' : 'cityNum'}
      onClick={() => {
        if (activeCityNum !== num) {
          checkScens('island', num);
          setState({ activeCityNum: num });
          onClose();
        }
      }}
    >
      {num}
    </div>
  )

  useEffect(() => {
    setState({ activeLocation: props.activeLocation })
  }, [props.activeLocation])


  useEffect(() => {
    setState({ activeCityNum: props.activeCityNum })
  }, [props.activeCityNum])

  const { activeLocation, step, activeCityNum, selectCityNum, swiperIndex  } = state;
  const { checkScens, visibel, mySkyCityNftNumber, mySkyCityNftNumberPage, scenesState } = props;
  return (visibel && <div className={classNames('LocationPanel', scenesState === 'island' && 'PurpleLocationPanel')}>
    <div className="LocationPanelBg">
      {step === 1 &&
       <div
       className="back"
       onClick={()=>{
        setState({step: 0, swiperIndex: 0})
      }}><ArrowLeftOutlined className="mr10"/>返回</div>}
      <div className="title">
        沐然星飞船时空穿梭机
      </div>
      <div className="subTitle">
        欢迎乘坐沐然星号时空穿梭机，选择你想去的地方
      </div>
      {step === 0 && <div className="locationGroup">

        {LOCATIONS.map(({ name, key }) => {
          if(scenesState === 'main' && key === 'main'){
            return false;
          }
          return <div
          className={activeLocation === key ? 'activeLocation' : ''}
          onClick={() => {
            if(key === 'main' ){
              if(activeLocation !== key){
                checkScens('main');
                onClose();
              }
            }else {
              setState({ activeLocation: key, step: 1 })
            }
          }}
          key={key}
        >
          {name}
        </div>
        })}
      </div>}


      {step === 1 && activeLocation === 'myIsland' && !!mySkyCityNftNumber?.length && !!mySkyCityNftNumberPage?.length && <div className="cityNumSwiperBox">
        <Swiper
          className="cityNumSwiper"
          ref={SwiperRef}
          indicatorProps={{ style: { opacity: 0 } }}
          onIndexChange={(e)=>{
            console.log(e);
            setState({
              swiperIndex: e
            })
          }}
        >
          {mySkyCityNftNumberPage.map((i) => (
            <Swiper.Item className="cityNumGroup" key={i}>
              <div>
                {mySkyCityNftNumber.slice((i - 1) * 12, i * 12).map((num) => (
                  cityNumDom(num)
                ))}
              </div>
            </Swiper.Item>
          ))}

        </Swiper>
       { mySkyCityNftNumberPage?.length > 1 && <>
        <div
          className={`SwiperIcon SwiperIconleft ${swiperIndex === 0?'grayIcon':''}`}
          onClick={() => {
            if(swiperIndex !== 0){
              SwiperRef.current?.swipePrev()
            }
          }}
        >
          <DoubleLeftOutlined />
        </div>
        <div
         className={`SwiperIcon SwiperIconRight ${swiperIndex === (mySkyCityNftNumberPage?.length - 1)?'grayIcon':''}`}

          onClick={() => {
            if(swiperIndex !== (mySkyCityNftNumberPage?.length - 1)){
              SwiperRef.current?.swipeNext()
            }
          }}
        >
          <DoubleRightOutlined />
        </div></>}
      </div>}
      {step === 1 && activeLocation === 'myIsland' && !(mySkyCityNftNumber?.length && mySkyCityNftNumberPage?.length) && <div className="noneCity">
       还未获得天空之城
      </div>}
      {step === 1 && activeLocation === 'otherIsland' && !!mySkyCityNftNumber && <div className="allCityNum">
      <div>  请输入您要拜访的门牌号：</div>
         <Input type="number" min={1} max={493} className="cityNumInput" onChange={(selectCityNum)=>{
           setState({
            selectCityNum
           })
         }}
         defaultValue={selectCityNum} />
      </div>}
      <div className="locationBtnGroup">
        <div className="locationBtn" onClick={onClose} >
          取消
        </div>
        {step === 1 && activeLocation === 'otherIsland' && <div className="locationBtn" onClick={() => {
          if ((activeCityNum !== selectCityNum) && selectCityNum && Number.isInteger(Number(selectCityNum))) {
            readerApi.getSkyCityByNftNumber({ nftNumber: selectCityNum }).then(() => {
              checkScens('island', selectCityNum);
              setState({ activeCityNum: selectCityNum });
              onClose();
            }).catch(()=>{
              Toast.show({content: '该城不存在或已销毁'})
            })

          }else if(activeCityNum !== selectCityNum){
            Toast.show({content: '请输入正确的门牌号'})
          }
        }} >
          起航
        </div>}
      </div>
    </div>
  </div>)
}

const LOCATIONS = [
  {
    name: '沐然星元宇宙',
    key: 'main'
  },
  {
    name: '我的天空之城',
    key: 'myIsland'
  },
  {
    name: '其他天空之城',
    key: 'otherIsland'
  },
]
