/*
 * @Author: weijiali
 * @Date: 2022-03-24 13:40:49
 * @LastEditors: weijiali
 * @LastEditTime: 2022-08-02 19:35:51
 */

import axiosServices from './request';

const VrmodelApi = axiosServices('vrmodel');

export default {
  /** 获取NFT藏品类型列表 */
  getNftTypeListPage: VrmodelApi('post', 'nft/getNftTypeListPage'),

  /** 获取在售NFT藏品类型列表  无需检验token */
  getClientNftTypeListPage: VrmodelApi('post', 'nft/getClientNftTypeListPage'),

  /** 客户端获取NFT藏品类型详情  无需检验token */
  getClientNftTypeInfo: VrmodelApi('get', 'nft/getClientNftTypeInfo'),

  /** 获取藏品类型下所有的交易记录 */
  getRecordByNftType: VrmodelApi('post', 'nft/getRecordByNftType'),

  // 获取当前蚂蚁链NFT的拥有者
  getAntNftHolder: VrmodelApi('get', 'nft/getAntNftHolder'),

  /** 获取某一类藏品编号列表 获取我的藏品列表*/
  getMyNftList: VrmodelApi('get', 'nft/getMyNftList'),

  /**（分页）获取我的藏品列表*/
  getMyNftPageList: VrmodelApi('post', 'nft/getMyNftPageList'),

  /** 获取该藏品类型下蚂蚁链的交易记录 */
  getAntRecordByNftType: VrmodelApi('post', 'nft/getAntRecordByNftType'),

  /** 蚂蚁链获取当前nftId的分享code */
  getNftShareId: VrmodelApi('get', 'nft/getNftShareId'),

  // 通过shareCode查看转赠状态
  getShareInfoByCode: VrmodelApi('get', 'nft/getShareInfoByCode', { noToken: true }),

  // 通过shareCode查看转赠状态
  receiveShareNft: VrmodelApi('post', 'nft/receiveShareNft'),

  // 获取当前空投列表
  getMyAirdropList: VrmodelApi('get', 'airdrop/getMyAirdropList'),

  // 点击我知道了，标识已读
  acceptMyAirdropList: VrmodelApi('post', 'airdrop/acceptMyAirdropList'),

  // 开盲盒
  openRandomBlindBox: VrmodelApi('post', 'blindbox/openRandomBlindBox'),

  // 开盲盒
  getMyBlindBoxRecord: VrmodelApi('post', 'blindbox/getMyBlindBoxRecord'),

  // 查询城主是否拥有魔女藏品
  checkHasWitch: VrmodelApi('get', 'nft/checkHasWitch')
};
