/*
 * @Author: weijiali
 * @Date: 2022-06-30 09:58:56
 * @LastEditors: weijiali
 * @LastEditTime: 2022-07-19 17:59:07
 */
import React, { useEffect, useRef } from 'react';
import { Input, Toast } from 'antd-mobile';
import { useSetState } from 'ahooks';

import DocumentTitle from 'react-document-title';
import readerApi from '@/shared/api/readerApi';

import cookies from '@/shared/utils/cookie';
// import { useActions, useSelector } from '@/shared/utils/redux';

import './index.scss';
import md5 from 'md5';

export default function Login(props) {
  const [state, setState] = useSetState({
    phoneNumber: '',
    password: '',
  });

  const sending = useRef(false);
  // const { getUserInfo } = useActions(({ reader }) => reader);

  const onChangePhoneNumber = (phoneNumber) => {
    setState({ phoneNumber });
  };

  const onChangePassword = (password) => {
    setState({ password });
  };

  const onLogin = () => {
    if (sending.current) {
      return false;
    }
    const { phoneNumber, password } = state;
    if (!phoneNumber) {
      return Toast.show({
        content: '请输入手机号'
      });
    }
    if (!password) {
      return Toast.show({
        content: '请输入密码'
      });
    }
    sending.current = true;
    readerApi.loginByPassword({
      phone: phoneNumber,
      password: md5(password),
    }).then(data => {
      sending.current = false;
      Toast.clear();
      cookies.setItem({ token: data.token });
      props.setPage('home')
    }).catch(err => {
      Toast.show({
        icon: 'fail',
        content: err?.message || '登录失败'
      });
      sending.current = false;
    });
  }

  return (
    <div className="Login">
      <DocumentTitle title="沐然星书藏" />
      <div className='loginBg'>
      <div className='hint mt30'>
          为了更好的浏览体验，请在WiFi环境下访问
          </div>
        <div className="content">
          <div className='inputBox'>
          <div className='phoneIcon inputIcon' />
            <Input
              type="number"
              placeholder="请输入手机号"
              onChange={onChangePhoneNumber}
              className="phone-input"
            />
          </div>
          <div className='inputBox'>
            <div className='passwordIcon inputIcon' />
            <Input
              type="password"
              placeholder={'请输入密码'}
              onChange={onChangePassword}
              className="phone-input"
            />
          </div>
          <div className='hint'>
          关注Meta沐然星书藏公众号<br />进入沐然星书藏注册账号
          </div>

          <div className="loginBtn" onClick={onLogin}>登录</div>
        </div>
      </div>
    </div>);
}
