import ev, {EventConstants} from "@/babylon/event/eventemitter";
import JSUtils from "@/babylon/utils/utils";
import * as BABYLON from "babylonjs";

class TouchingMoving {
  constructor(camera, engine,  props) {
    this.camera = camera;
    this.speed = props?.speed || 0.01;
    this.props = props;
    this.scene = props.scene
    this.flag = false; //解决持续双手导致方向错位的问题

    this.movingParams = null;
    this.rotationParams = null;
    this.oldRotationParams = null;

    ev.on(EventConstants.TOUCH_MOVING, JSUtils.debounce(this.inMoving, 50))
    ev.on(EventConstants.TOUCH_END,this.clearMoving)
    ev.on(EventConstants.CAMERA_TOUCH_MOVING, JSUtils.debounce(this.inRotation, 50))
    ev.on(EventConstants.CAMERA_TOUCH_END,this.clearRotation)

    engine.runRenderLoop(this.movingCamera)
  }

  inMoving = (params) => {
    this.movingParams = params;
  }

  clearMoving = () =>  {
    this.movingParams = null;
    if(this.props?.stopMoving) {
      this.props.stopMoving(this.movingParams);
    }
  }

  inRotation = (params) => {
    this.rotationParams = params;
  }

  clearRotation= () => {
    this.rotationParams = null;
    this.oldRotationParams = null;
  }

  movingCamera = () => {
    if(this.movingParams && this.flag) {
      const params = this.movingParams;
      const speed = params.speed === 1 ? 1 : 1.3;
      const y = Math.sin((params.rotationRate/360)*(2*Math.PI)) * this.speed * speed;
      const x = Math.cos((params.rotationRate/360)*(2*Math.PI)) * this.speed * speed;
      this.translateTransform = BABYLON.Vector3.TransformCoordinates(new BABYLON.Vector3(x, 0, -y), BABYLON.Matrix.RotationY(this.camera.rotation.y));
      this.camera.cameraDirection.addInPlace(this.translateTransform);
    }
    if(this.rotationParams && this.rotationParams !== this.oldRotationParams && !this.flag) {
      this.camera.cameraRotation.y += this.rotationParams.pageX/800;
      this.camera.cameraRotation.x += this.rotationParams.pageY/800;
      this.oldRotationParams = this.rotationParams;
    }

    this.flag = !this.flag;
  }
}


export default TouchingMoving
