import { isNotEmpty } from './validations';

const Utils = {

  json2params(json, slice = '&') {
    return Object.keys(json).reduce((acc, item) => {
      return String(acc) + item + '=' + json[item] + slice;
    }, '').slice(0, -1);
  },

  params2json(params = '', slice = '&') {
    return params.split(slice).reduce((acc, item) => {
      let arr = item.split('=');
      return { ...acc, ...{ [arr[0]]: arr[1] }};
    }, {});
  },

  browser() {
    const ua = navigator.userAgent.toLowerCase();
    return {
      ios: !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/i),
      android: ua.indexOf('android') > -1 || ua.indexOf('linux') > -1,
      iPad: ua.indexOf('ipad') > -1,
      wechat: ua.toLowerCase().indexOf('micromessenger') > -1,
      aliPay: ua.toLowerCase().indexOf('alipay') > -1
    };
  },

  isWeAppEnv() {
    return window.__wxjs_environment && window.__wxjs_environment === 'miniprogram';
  },
  searchAssign(value = {}) {
    let _copy = Object.assign({}, value);
    for(let key in value) {
      //han;某些地方会从url读取参数
      if(!isNotEmpty(value[key]) || value[key] === 'undefined') {
        delete _copy[key];
      }
    }
    return _copy;
  }
};

export default Utils;
