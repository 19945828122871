import React, { useEffect } from "react";

import './IslandGuidePanel.scss';

export default function IslandGuidePanel(props) {

  const { onClose } = props;

  return <div className="IslandGuidePanel" onClick={onClose}>
    <div className="GuidePanelBg">
      <div className="content">
        <div className="top-left"></div>
        <div className="top">
        欢迎来到天空之城！
        </div>
        <div className="middle">
          作为沐然星元宇宙创世贵族的空中城堡，是最顶配的私人住宅，仅通过乘坐沐然星号可以登陆。城主将开放元宇宙专属个人藏品室。

        </div>
        <div className="bottom">
          <span className="rightIcon" />
        欢迎回家
        </div>

      </div>
      <div className="person">
        <img src="https://oss.5rs.me/oss/uploadfe/png/79f3329616b30658bad7c1278983bf53.png" />
      </div>

    </div>

  </div>
}

