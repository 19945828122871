/*
 * @Author: weijiali
 * @Date: 2022-03-24 11:21:50
 * @LastEditors: weijiali
 * @LastEditTime: 2022-07-26 19:10:44
 */

import axiosServices from './request';

const ReaderApi = axiosServices('reader');

export default {
  /** 手机号+验证码登陆 */
  loginByPhone: ReaderApi('post', 'reader/loginByPhone'),
  loginByPhoneOld: ReaderApi('post', 'reader/loginByPhone'),
  /** 获取我的藏品列表（获取当前类型我的NFT列表） 已废弃*/
  getMyNftList: ReaderApi('get', 'nft/getMyNftList'),

  /** 获取当前nftAddress的分享code*/
  getNftShareId: ReaderApi('get', 'nft/getNftShareId'),

  /** 根据Token获取当前读者信息*/
  // getReaderInfo: ReaderApi('get', 'reader/getReaderInfo'),
  getReaderInfo: function(data) {
    return ReaderApi('get', 'reader/getReaderInfo', data?.token ? {
      headers: {
        token: data.token
      }
    } : null);
  }(),

  // 微信授权登录
  authorizeLogin: ReaderApi('post', 'reader/authorizeLogin'),

  // 设置密码
  setPassword: ReaderApi('post', 'reader/setPassword'),

  // 账号密码登陆
  loginByPassword: ReaderApi('post', 'reader/loginByPassword'),

  /** 根据Token获取当前读者信息 图书订购仅用于检验token是否过期*/
  getBookOrderReaderInfo: ReaderApi('get', 'reader/getReaderInfo', { notCheck401: true }),

  // 实名认证
  idCardAudit: ReaderApi('get', 'reader/idCardAudit'),

  // 更新读者信息
  updateReaderInfo: ReaderApi('post', 'reader/updateReaderInfo'),

  // 获取当前NFT的拥有者
  getNftHolder: ReaderApi('get', 'nft/getNftHolder', { noToken: true }), //废弃

  // 获取藏品类型下所有的交易记录
  getRecordByNftType: ReaderApi('post', 'nft/getRecordByNftType'),

  // 获取当前NFT的拥有者
  getShareInfoByCode: ReaderApi('get', 'nft/getShareInfoByCode', { noToken: true }),

  // 接受别人赠送的NFT
  receiveNftGift: ReaderApi('get', 'nft/receiveNftGift'),

  // 读者获得新的的NFT
  addNft: ReaderApi('post', 'nft/addNft'),

  // 获取用户某本书下的优惠券
  getMyAppCouponList: ReaderApi('get', 'coupon/getMyAppCouponList', { redirectUrl: '/nft-store/store' }),

  // 新建用户地址
  addAddress: ReaderApi('post', 'address/addAddress'),

  // 获取用户地址列表
  getReaderAddressList: ReaderApi('get', 'address/getReaderAddressList'),

  // 获取用户地址详情
  getAddressInfo: ReaderApi('get', 'address/getAddressInfo'),

  // 获取默认地址信息
  getReaderDefaultAddress: ReaderApi('get', 'address/getReaderDefaultAddress', { notCheck401: true }),

  // 更新用户地址信息
  updateAddress: ReaderApi('post', 'address/updateAddress'),

  // 删除我的地址
  deleteAddress: ReaderApi('post', 'address/deleteAddress'),

  // 渠道接口
  // 获取渠道
  getChannelByHost: ReaderApi('get', 'channel/getChannelByHost'),

  // 获取渠道banner图
  getChannelBanners: ReaderApi('get', 'channel/getChannelBanners'),



  // 获取当前用户持有的天空之城门牌号
  getMySkyCityNftNumber: ReaderApi('get', 'nft/getMySkyCityNftNumber'),
  
  
  // 根据门牌号拜访天空之城
  getSkyCityByNftNumber: ReaderApi('get', 'nft/getSkyCityByNftNumber'),

  // 创世主签到
  skyCitySignIn: ReaderApi('post', 'nft/skyCitySignIn'),

  // 分页查询留言
  getLeaveMessage: ReaderApi('post', 'nft/getLeaveMessage'),

  // 新增留言
  leaveMessage: ReaderApi('post', 'nft/leaveMessage'),
  
  // 分页查询访客记录
  getVisitorRecords: ReaderApi('post', 'nft/getVisitorRecords'),
};
