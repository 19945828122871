import MainScene from "@/babylon/scenes/MainScene";
import IslandScene from "@/babylon/scenes/IslandScene";

const scenes = {
  MAIN: MainScene,
  ISLAND: IslandScene
}

export default scenes;

export const GAME_SCENES = {
  MAIN: 'MAIN',
  ISLAND: 'ISLAND'
}

