
import * as BABYLON from 'babylonjs';
import 'babylonjs-loaders';

import scenes from "@/babylon/scenes";
import LoadingScene from "@/babylon/scenes/LoadingScene";

class GameManager {
  constructor(container, props) {
    this.engine = new BABYLON.Engine(container);
    this.container = container;
    this.currentScene = null;
    this.engine.runRenderLoop(() => {
      this.currentScene?.render();
    });

    // 设置加载
    this.engine.loadingScreen = new LoadingScene({
      text: "加载中"
    })

    this.sceneProps = {
      engine: this.engine,
      container: this.container
    }
  }

  navigatorTo = (sceneName, params = {}) => {
    if(scenes[sceneName]) {
      this.currentScene = new scenes[sceneName](Object.assign({}, this.sceneProps, params));
    } else {
      console.log('暂无此场景')
    }
  }
}

export default GameManager;
