
const LocalStorage = window.localStorage;

export function put(key, value) {
  LocalStorage.setItem(key, value);
}

export function get(key) {
  return LocalStorage.getItem(key);
}

export function remove(key) {
  return LocalStorage.removeItem(key);
}

export function clear() {
  LocalStorage.clear();
}
