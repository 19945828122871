import React, { useRef } from "react";
import { useSetState, useRequest } from "ahooks";
import readerApi from '@/shared/api/readerApi';
import { CloseOutline, ExclamationCircleFill } from 'antd-mobile-icons';

import './RewardPanel.scss';
import { Toast } from "antd-mobile";
import dayjs from 'dayjs';

export default function RewardPanel(props) {

  const [state, setState] = useSetState({
    rewardData: null
  })
  const { rewardData } = state;
  const { nftId, visibel, onClose, isSignIn, isOwn, activeCityNum, updateRewordData } = props;
  const sending = useRef(false);
  const startTime = dayjs('2022-10-28 10:00:00');
  const endTime = dayjs('2022-11-01 12:00:00');

  return (visibel && <div className="RewardPanel">
    <div className="RewardPanelBg">
      <div className="left-top"></div>
      <div className="right-bottom"></div>
      <div
        className="close"
        onClick={()=>{
          setState({
            rewardData: null,
          })
          onClose();
        }}>

          <CloseOutline />
        </div>
      {!rewardData && <>
        <div className="title">
        沐然星万圣魔幻创世城福利
        </div>
        <div className="activeTime">
            活动时间（{startTime.format('MM/DD HH:mm')} - {endTime.format('MM/DD HH:mm')}）
          </div>
        <div className="content">
          <div className="activeDetail">
            <div>活动一</div>
            <div>
            创世主福利：活动期间登陆所属天空之城签到，即可领取万圣节【飞天斗篷】藏品！每个创世岛皆可以领取一份！
            </div>
          </div>
          <div className="activeDetail">
            <div>活动二</div>
            <div>
            全网福利：拜访其他创世主天空之城，并首次留言万圣节祝福语，可获得万圣节藏品盲盒。单人每个城堡首次留言获得1个盲盒，单人上限5个。盲盒数量有限，先到先得。
            </div>
          </div>
        </div>

        {!!isOwn ?<div className="RewardBtn RewardLongBtn" onClick={() => {
          if(dayjs().isAfter(endTime)){
            return Toast.show({
              content: <div style={{textAlign: 'center'}}>福利活动已结束</div>,
              afterClose: onClose
            });
          }
          if(sending.current){
            return false;
          }
          if (isSignIn) {
            onClose()
          }else{
            sending.current = true;
            readerApi.skyCitySignIn({ nftId }).then((data) => {
              setState({
                rewardData: data?.nft,
              })
              sending.current = false;
              updateRewordData(activeCityNum)

            }).catch(({message})=>{
              Toast.show({
                content: <div style={{textAlign: 'center'}}>{message || '领取失败'}</div>,
                afterClose: onClose
              });
              sending.current = false;
            })
          }
        }} >
          {dayjs().isAfter(endTime) ? '万圣节活动已结束' : isSignIn ? '福利已被领取' : `签到领取万圣节福利`}
        </div> : <div className="h50" />}

        </>}

        {!!rewardData && <>
        <div className="titleLogo" />
        <div className="rewardContent">
         <div><img src={rewardData?.coverImg}/></div>
         <div>
           <div className="nftTypeName">{rewardData?.nftTypeName}</div>
           <div className="nftNumber">{`藏品号： #${rewardData?.nftNumber}`}</div>
         </div>
        </div>

          <div className="hint">
          <ExclamationCircleFill />
          关注Meta沐然星书藏公众号，前往沐然星书藏查看藏品信息
          </div>

         <div className="RewardBtn" onClick={() => {
          setState({
            rewardData: null,
          })
          onClose();
        }} >
          开心收下
        </div>

      </>}

    </div>
  </div>)
}

