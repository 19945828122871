import React, { useEffect } from "react";
import { CloseOutline, ExclamationCircleFill } from 'antd-mobile-icons';
import readerApi from '@/shared/api/readerApi';
import { useSetState } from "ahooks";

import './MessagePanel.scss';
import { Empty, List } from "antd-mobile";
import dayjs from "dayjs";

export default function MessagePanel(props) {

  const { nftId, visibel } = props;

  const [state, setState] = useSetState({
    messageList: [],
    step: 0,
    // rewardData: {coverImg: 'https://oss.5rs.me/oss/uploadfe/jpg/a36e658c0a8e6de8997297267aa80581.jpg'}
  })

  const onClose = ()=>{
    props.onClose();
    setState({
      rewardData: null,
      step: 0
    })
  }
  const leaveMessage = (message) => {
    readerApi.leaveMessage({ nftId, message }).then((data) => {
      getLeaveMessage();
      setState({
        step: 0
      })
      if (data?.nft) {
        setState({
          rewardData: data?.nft
        })
      }else{
        setState({
          step: 0
        })
      }

    }).catch(() => {
      setState({
        step: 0
      })
      getLeaveMessage();

    })
  }

  const getLeaveMessage = ()=>{
    readerApi.getLeaveMessage({ nftId, currentPage: 1, numPerPage: 50 }).then((data) => {
      if(Array.isArray(data.recordList)){
        setState({
          messageList: data.recordList
        })
      }

    }).catch(({message})=>{

    })
  }



  useEffect(()=>{
    if(nftId){
      getLeaveMessage()
    }
  }, [nftId])

  const {step, rewardData}= state;

  return (visibel && <div className="MessagePanel">
   {!rewardData && <div className="MessagePanelBg">
      <div className="top">
        <span>留言板</span>
        <CloseOutline onClick={onClose}/>
      </div>
      {!step && <div className="content">
        {state.messageList?.length ?
          <div className="messageList">
            {state.messageList.map(({ userName, avatar, nftMessageContext, createTime, secretPhone }) => (
              <div className="messageItem">
                <img src={avatar || DEFAULT_URL} />
                <div className="messageItemContent">
                  <div><span>{userName || secretPhone || '--'}</span><span>{dayjs(createTime).format('MM-DD HH:mm')}</span></div>
                  <div>{nftMessageContext}</div>
                </div>
              </div>
            ))}
          </div>
          :
          <Empty description='暂无好友留言哦~' className="empty" />}

      </div>}
      {!!step && <div className="content">
        <div className="messageTextContent">
          <div className="messageTextHint">点击祝福发送留言</div>
          <div className="messageTextList">
            {MESSAGE_TEXT_ARR.map((text) => (
              <div className="messageTextItem" key={text} onClick={() => {
                leaveMessage(text)
              }}>
                {text}
              </div>
            ))}
          </div>
        </div>
      </div>}
      <div className="MessageBtn"
        onClick={() => {
          setState({
            step: step ? 0 : 1
          })
        }}>
          {step?'取消':'留言'}
      </div>
    </div>}
    {!!rewardData &&
      <div className="MessageRewardPanelBg">
        <CloseOutline onClick={onClose} className="close" />
        <div className="title">留言成功</div>
        <div className="subTitle">获得活动藏品盲盒1个</div>
        <div><img src={rewardData?.coverImg} /></div>
        <div className="hint">  <ExclamationCircleFill />
          开启盲盒收集斗篷、扫帚、南瓜帽，集齐可获得限定藏品【万圣节限定-小魔女】（特殊编号获得特殊好礼）一份</div>
        <div className="hint">关注Meta沐然星书藏公众号，前往沐然星书藏查看藏品信息</div>

        <div className="rewardBtn" onClick={onClose} >
          开心收下
        </div>
      </div>}
  </div>)
}


const MESSAGE_TEXT_ARR = [
  '叮咚！不给糖就捣蛋',
  '可爱鬼来报道',
  '是个小妖怪！自由又自在！',
  '一起去捣蛋嘛',
]


const DEFAULT_URL = 'https://oss.raysgo.com/oss/uploadfe/jpg/904f0ac1567aa96f68a87adf7a5b2008.jpg';
