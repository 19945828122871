import app from '@wtdc/feed';
import Container from './App';

import './sass/app.scss';

app
  .useHistory()
  .useRedux([])
  .useRouter(Container)
  .attach(document.getElementById('App')); //渲染界面

if(module.hot) {
  module.hot.accept();
}
