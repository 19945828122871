import * as BABYLON from 'babylonjs';
import cameraUtils from "@/babylon/utils/cameraUtils";
import TouchingMoving from "@/babylon/utils/TouchingMoving";
import ev, {EventConstants} from "@/babylon/event/eventemitter";
import vrmodelApi from '@/shared/api/vrmodelApi';
import Utils from '@/shared/utils/utils';

class IslandScene {

  constructor(props) {
    const {engine, container, ...restProps} = props;
    this.engine = engine;
    this.container = container;

    this.props = restProps;

    // 开启loading
    ev.emit(EventConstants.LOADING_STATUS_CHANGE, true);

    this.scene = new BABYLON.Scene(this.engine);
    this.camera = cameraUtils.createFollowCamera(this.scene, this.container);
    this.camera.rotation = new BABYLON.Vector3(0, 5*Math.PI / 12, 0);
    this.camera.position = new BABYLON.Vector3(-255, 0, 0);
    this._load(this.scene);

    this.light = new BABYLON.HemisphericLight("light", new BABYLON.Vector3(0, 1, 0), this.scene);
    // this.light = new BABYLON.HemisphericLight("light", new BABYLON.Vector3(0, 1, 0), this.scene);
    this.light.intensity = 0.9;

    // this.scene.onBeforeRenderObservable.add(() => {
    //   if (this.cameraBox) {
    //     this.cameraBox.position.x = this.camera._position._x;
    //     this.cameraBox.position.z = this.camera._position._z;
    //   }
    // })
    this.scene.gravity = new BABYLON.Vector3(0, -0.1, 0);
    this.scene.collisionsEnabled = true;
    return this.scene;
  }

  _load() {
    const  {scene, camera, props} = this;
      // 小魔女 和传送点
    vrmodelApi.checkHasWitch({ nftNumber: props.nftNumber }).then((data) => {
      if(data){

      BABYLON.SceneLoader.ImportMesh('', 'https://file.metabookstore.com.cn/vrmodels/h5/main/', 'wanshengjienvjuese7.glb', this.scene, (meshes) => {
        const mesh = meshes[0];
        mesh.scaling = new BABYLON.Vector3(200, 200, 200);
        mesh.position = new BABYLON.Vector3(-248, -2, -12);
        mesh.rotation = new BABYLON.Vector3(0, Math.PI / 1.5, 0);
      });
      // BABYLON.MeshBuilder.CreateCylinder("cameraBox", { width: 5, height: 2, depth: 5 })


      // BABYLON.SceneLoader.ImportMesh('', 'https://file.metabookstore.com.cn/vrmodels/h5/main/', 'chuansongdian.glb', this.scene, (meshes) => {
      //   const mesh = meshes[0];
      //   mesh.position = new BABYLON.Vector3(-246, -2, -11.6);
      //   mesh.scaling = new BABYLON.Vector3(1.5, 1.5, 1.5);
      //   mesh.actionManager = new BABYLON.ActionManager(this.scene);
      //   mesh.material = new BABYLON.StandardMaterial("mansionHitBox", this.scene);
      //   // mesh.actionManager.registerAction(new BABYLON.ExecuteCodeAction(
      //   //   { trigger: BABYLON.ActionManager.OnIntersectionEnterTrigger, parameter: this.cameraBox },
      //   //   function () {
      //   //     ev.emit(EventConstants.GUIDE_STATUS_CHANGE, 'MONV_C_P');
      //   //   }
      //   // ));
      // });
      }
    })

    // this.cameraBox = BABYLON.MeshBuilder.CreateCylinder("cameraBox", { width: 4, height: 2, depth: 4 });
    // this.cameraBox.position.y = -1;



    BABYLON.SceneLoader.ImportMesh('', 'https://file.metabookstore.com.cn/vrmodels/h5/main/',  Utils.browser().ios?'wanshengjie_v16.glb':'wanshengjie_v7.glb', this.scene, (meshes) => {
      const mesh = meshes[0];
      mesh.scaling = new BABYLON.Vector3(-0.1, 0.1, 0.1);
      mesh.position = new BABYLON.Vector3(-130, -10, -10);

      meshes.forEach((item, i) => {
        if (item.name.includes('mianban') || item.name.includes('Object002')) {
          item.checkCollisions = true;
          item.isVisible = false;
        }
      });

      // console.log(this.camera.position.x += 5);
      // 调整初始点位置
      this.camera.position.x += 26;
      this.camera.position.z += -8.68;
      this.camera.position.y += 0.605;
      //
      this.camera.rotation.y += 3.47;
      this.camera.rotation.z += 0.06;
      // console.log(this.camera.rotation);
      console.log(this.camera.position, 'this.camera.position start');


      // this.camera.position = new BABYLON.Vector3(0, 2, 0);
      ev.emit(EventConstants.LOADING_STATUS_CHANGE, false)
    })


    
    // const  box1 = BABYLON.Mesh.CreateBox('box1', 10, scene);
    // box1.position = new BABYLON.Vector3(-245, -2, -12);
    // box1.actionManager = new BABYLON.ActionManager(this.scene);
    // box1.actionManager.registerAction(new BABYLON.ExecuteCodeAction(
    //   { trigger: BABYLON.ActionManager.OnIntersectionEnterTrigger, parameter: this.cameraBox },
    //   function () {
    //     ev.emit(EventConstants.GUIDE_STATUS_CHANGE, 'MONV_C');
    //   }
    // ));

    



    // 天空盒
    const skybox = BABYLON.MeshBuilder.CreateBox("skyBox", { size: 1000.0 }, scene);
    const skyboxMaterial = new BABYLON.StandardMaterial("skyBox", scene);
    skyboxMaterial.backFaceCulling = false;
    skyboxMaterial.reflectionTexture = new BABYLON.CubeTexture("https://file.metabookstore.com.cn/vrmodels/skybox/starry/starry8/starry", scene);
    skyboxMaterial.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE;
    skyboxMaterial.diffuseColor = new BABYLON.Color3(0, 0, 0);
    skyboxMaterial.specularColor = new BABYLON.Color3(0, 0, 0);
    skybox.material = skyboxMaterial;


    //Set gravity for the scene (G force like, on Y-axis)
    scene.gravity = new BABYLON.Vector3(0, -0.9, 0);

    // Enable Collisions
    scene.collisionsEnabled = true;

    //Then apply collisions and gravity to the active camera
    camera.checkCollisions = true;
    camera.applyGravity = true; //重力

    //Set the ellipsoid around the camera (e.g. your player's size)
    camera.ellipsoid = new BABYLON.Vector3(1, 1.5, 1);


    new TouchingMoving(this.camera, this.engine, {
      speed: 0.01,
      startMoving: (params) => {
        // this.scene.beginAnimation(result.skeletons[0], 0, 100, true, 1.0);
        // console.log(this.camera.rotationQuaternion, 'this.camera.rotationQuaternion++++++++++++++++++');
        // console.log(this.camera.cameraDirection, this.camera.cameraRotation, this.camera._cameraRotationMatrix);
      },
      stopMoving: (params) => {
        // this.scene.stopAnimation(result.skeletons[0]);
        console.log(this.camera.position, 'this.camera.position')
      }
    });

  }

}

export default IslandScene;
