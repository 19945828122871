
// 摇杆控制器
export const TOUCH_MOVING  = 'TOUCHING_MOVING';
export const TOUCH_END  = 'TOUCHING_END';

// 面板控制器
export const LOADING_STATUS_CHANGE = 'LOADING_STATUS_CHANGE';

// 相机控制器
export const CAMERA_TOUCH_MOVING = 'CAMERA_TOUCH_MOVING';
export const CAMERA_TOUCH_END = 'CAMERA_TOUCH_END';


// 介绍面板控制
export const GUIDE_STATUS_CHANGE = 'GUIDE_STATUS_CHANGE';

// 场景切换
export const SCENE_CHANGE = 'SCENE_CHANGE';