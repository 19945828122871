/*
 * @Author: Paco
 * @Date: 2018-11-23 20:39:54
 * @LastEditTIme: Paco
 * @Description:
 */

import Axios from 'axios';
import uuid from '@wtdc/shortid';
import { BASE_API_ROOT } from './config';
import  cookies  from '@/shared/utils/cookie';

const axios = Axios.create();

axios.defaults.baseURL = BASE_API_ROOT;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

/**
 * 添加ie缓存清理
 * @params config
 * @returns {*}
 */
const addUidForIe = function(config) {
  if(config.method === 'get') {
    if(config.params) {
      config.params.t = uuid();
    } else {
      config.params = {
        t: uuid()
      };
    }
  }
  return config;
};

/**
 * 添加headers参数
 * @param {*} config
 * @returns config
 */
const addCommonData = function(config) {
  config.headers = config.headers || {};
  const cookie = cookies.get()?.token || config?.params?.token;
  // noToken 无需token
  if(cookie && !config.noToken) {
    config.headers.token = cookie;
  }
  return config;
};

const formatResponse = (response) => {
  // todo: 修改判断
  const data = response?.data;
  const config = response?.config || {};
  if(data.errCode === 0) {
    return data.data;
  } else if(data.errCode === ERROR_CODE.AUTHORIZE_ERROR) {
    removeCookieUser();

    // notCheck401: token失效时仅清空cookie，不返回首页
    if(config.notCheck401) {
      return false;
    }

    // redirectUrl: 自定义重定向url，如图书订购业务token失效时的重定向url为图书订购首页
    if(config.redirectUrl) {
      window.location.href = window.location.origin + config.redirectUrl;
      return false;
    }

    window.location.href = window.location.origin + '/nft-store/login';
    return Promise.reject(data);
  } else {
    return Promise.reject(data);
  }
};

const ERROR_CODE = {
  SERVER_ERROR: 500,
  NETWORK_ERROR: 503,
  AUTHORIZE_ERROR: 401
};

const removeCookieUser = () => {
  // cookies.remove(); 无法清除 ？？？
  document.cookie = 'userInfo=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  cookies.remove();
};

const formatAjaxError = (error) => {
  const response = error?.response;
  if(response && response.status === ERROR_CODE.SERVER_ERROR) {
    return Promise.reject({
      message: '服务器内部错误，请稍后重试！',
      status: response.status
    });
  }
  if(response && response.status === ERROR_CODE.NETWORK_ERROR) {
    return Promise.reject({
      message: '网络问题，请稍后重试！'
    });
  }
  return Promise.reject(response?.data || {
    message: '服务器开小差了呢！'
  });
};

axios.interceptors.request.use(addUidForIe, err => {
  console.log(err);
});

axios.interceptors.request.use(addCommonData, err => {
  console.log(err);
});

//Add a response interceptor
axios.interceptors.response.use(formatResponse, formatAjaxError);

export default function axiosServices(root) {
  console.log(root, 123);
  return (method, route, config) => data => {
    const params = method === 'get' ? { params: data, ...config } : data;
    return axios[method](
      `${root}/${route}`,
      params,
      config
    );
  };
}
