import React from "react";
import './App.scss'
import Home from "./page/home/Home";
import Login from "./page/login";
import cookies from '@/shared/utils/cookie';

export default class App extends React.Component {
  state = {
    page: cookies.get()?.token ? 'home' : 'login'
  }

  setPage = (page) => {
    this.setState({
      page: cookies.get()?.token ? 'home' : 'login'
    })
  }

  render() {
    const { page } = this.state;
    return <div>
      {page === 'login' && <Login setPage={this.setPage} />}
      {page === 'home' && <Home />}
    </div>
  }
}
