import React from "react";
import ev, {EventConstants} from '@/babylon/event/eventemitter';

import './index.scss'

export default class EasyTouch extends React.Component{

  constructor(props) {
    super(props);

    this.startPosition = null;
    this.state = {
      movePosition: {
        pageX: 48,
        pageY: 48
      },
      rotationDeg: 0, // 角度变换
      touching: false, // 按键触摸
      overflow: false  // 是否超过圆框
    }

    this._init();
    this.touchRadius = 48;// 60 -12  半径
    this.distancePow = Math.pow(this.touchRadius, 2) // 计算半径平方
  }

  _init = () => {
    this.portrait = window.orientation === 0; // 是否竖屏
    this.heigth =  Math.min(window.innerWidth, window.innerHeight);// 竖屏之后的高度
    this.centerPosition = {
      x: 90,
      y:this.heigth - 90
    }
  }

  touchStart = (e) => {

    // 双指不操作
    if(e.changedTouches?.length > 1) {
      return;
    }
    const touch = e.changedTouches[0]; //单指
    this.startPosition = {
      pageX: this.portrait ?  touch.pageY : touch.pageX,
      pageY: this.portrait ? this.heigth  - touch.pageX :  touch.pageY
    }
    this.setState({
      touching: true
    })
  }

  touchMove = (e) => {
    if(!this.startPosition) {
      return null;
    }
    if(this.props.touchMove){
      this.props.touchMove(this.startPosition);
    }

    const touchEvent = e.changedTouches[0];
    if(!touchEvent) return ;
    // 横屏切换
    const touch ={
      pageX: this.portrait ?  touchEvent.pageY : touchEvent.pageX,
      pageY: this.portrait ? this.heigth  - touchEvent.pageX :  touchEvent.pageY
    }; //单指
    const distance = Math.pow(touch.pageX - this.centerPosition.x , 2) + Math.pow(touch.pageY - this.centerPosition.y, 2);
    let deg = 360* Math.atan((touch.pageY - this.centerPosition.y)/(touch.pageX - this.centerPosition.x))/ (2*Math.PI)
    if(touch.pageX - this.centerPosition.x < 0) {
      deg =  deg + 180;
    }
    const x =  touch.pageX - 42;
    const y = this.heigth  - touch.pageY - 42;

    // 超过半径平方，走角度
    if(distance > this.distancePow) {
      this.setState({
        rotationDeg: deg,
        movePosition: {
          pageX: this.centerPosition.x + (this.touchRadius *Math.cos((deg/360) *(2*Math.PI))) - 42,
          pageY: this.heigth  - (this.centerPosition.y + (this.touchRadius * Math.sin((deg/360)*(2*Math.PI)))) - 42,
        },
        overflow: true
      })
    } else {
      // 不超过半径平方，走位置
      this.setState({
        movePosition: {
          pageX: x,
          pageY: y,
        },
        rotationDeg: deg,
        overflow: false
      })
    }
    ev.emit(EventConstants.TOUCH_MOVING, {
      rotationRate: deg,
      speed: distance > this.distancePow ? 2 : 1
    })
  }

  touchEnd = () => {
     this.startPosition = null;
     this.setState({
       movePosition: {
         pageX: 48,
         pageY: 48
       },
       touching: false,
       overflow: false
     })
    ev.emit(EventConstants.TOUCH_END)
  }

  componentDidMount() {
    window.addEventListener('orientationchange', this._init, false)
  }

  render() {
    const {movePosition, rotationDeg, touching, overflow } = this.state;
    return <div className="easy-touch" ref={'container'}
      onTouchMove={this.touchMove}
      onTouchEnd={this.touchEnd}
      onTouchStart={this.touchStart}
    >
      <div className={`easy-touch-container dis-flex flex-center ${touching ? 'touching' : ''}`}>
        <div className="easy-touch-cur-box">
          <img className="easy-touch-cur" src="https://s.newscdn.cn/@yuanmao/web/0.2.78/assets/virtualJoystick_blur_bg.svg"/>
        </div>
        <div className="bling-box" style={{left: movePosition.pageX, bottom: movePosition.pageY, transform: `rotate(${rotationDeg}deg)`}}>
          <img
            className="bling"
            src="https://s.newscdn.cn/@yuanmao/web/0.2.78/assets/virtualJoystick_pos.svg"/>
          <img className={`arrow ${overflow ? '' : 'hidden'}`}
               src="https://s.newscdn.cn/@yuanmao/web/0.2.78/assets/virtualJoystick_arrow_ico.svg"
          />
        </div>
      </div>
    </div>
  }
}
