import React, {useEffect, useRef} from "react";
import GameManager from "@/babylon/GameManager";
import {GAME_SCENES} from "@/babylon/scenes";

import EasyTouch from "@/components/easyTouch";
import ev, {EventConstants} from "@/babylon/event/eventemitter";
import Loading from "@/components/loading";
import CameraTouch from "@/components/cameraTouch";
import './Home.scss'
import Set from "@/components/set";

export default class Home extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showLoading: false,
      portrait: window.innerWidth < window.innerHeight,
      showElevator: false
    }
  }

  componentDidMount() {

    // 判断是否变化loading
    ev.on(EventConstants.LOADING_STATUS_CHANGE, (loading) => {
      this.setState({
        showLoading: loading
      })
    })

    this.manager = new GameManager(this.container);
    this.manager.navigatorTo(GAME_SCENES.MAIN);

    window.addEventListener('orientationchange', this.orientationChange, false)
  }

  checkScens = (scens, nftNumber)=>{
    this.manager.navigatorTo(scens, {
      nftNumber
    });
  }

  orientationChange = () => {
    this.setState({
      portrait: window.orientation <= 0
    })
  }

  touchMove = () => {
    this.setState({
      showElevator: true
    })
  }

  render() {
    const {showLoading, portrait} = this.state;
    const width = Math.max(window.innerWidth, window.innerHeight);
    const height = Math.min(window.innerWidth, window.innerHeight);

    let wrapStyle;
    if(portrait) {
      wrapStyle = {
        width: width,
        height: height,
        top: (width - height)/2,
        left: -(width - height)/2,
        transform: 'rotate(90deg)'
      };
    } else {
      wrapStyle = {
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        transform: 'none'
      };
    }

    const showPanel = showLoading;
    return <div className={'panel-container'}>
      <div className={`world-wrap ${showPanel ? 'show' : ''}`} style={wrapStyle}>
        <div className={`ui-wrap`}>
          <Loading visiable={showLoading}/>
        </div>
      </div>
      <div className={'touch-wrap'} style={wrapStyle}>
        {/* 移动 */}
        {!this.state.hiddenTouch && <EasyTouch touchMove={this.touchMove} />}
        {/* 旋转 */}
        {!this.state.hiddenTouch && <CameraTouch />}
        <Set checkScens={this.checkScens} />
      </div>
      <canvas id="renderCanvas" ref={ref => this.container = ref} style={wrapStyle}/>

    </div>
  }
}
