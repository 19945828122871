import React from "react";
import ev, {EventConstants} from '@/babylon/event/eventemitter';

import './index.scss';

export default class CameraTouch extends React.Component{

  constructor(props) {
    super(props);

    this.startPosition = null;
    this._init();
  }

  _init = () => {
    this.portrait = window.orientation === 0; // 是否竖屏
    this.heigth =  Math.min(window.innerWidth, window.innerHeight);// 竖屏之后的高度
  }

  touchStart = (e) => {
    // 双指不操作
    if(e.changedTouches?.length > 1) {
      return;
    }
    const touch = e.changedTouches[0]; //单指
    if(!touch) return;
    this.startPosition = {
      pageX: this.portrait ?  touch.pageY : touch.pageX,
      pageY: this.portrait ? this.heigth  - touch.pageX :  touch.pageY
    }
  }

  touchMove = (e) => {
    if(!this.startPosition) {
      return null;
    }
    const touchEvent = e.changedTouches[0];
    if(!touchEvent) return ;
    // 横屏切换
    const touch ={
      pageX: this.portrait ?  touchEvent.pageY : touchEvent.pageX,
      pageY: this.portrait ? this.heigth  - touchEvent.pageX :  touchEvent.pageY
    };
    const distanceX = (touch.pageX - this.startPosition.pageX);
    const distanceY = (touch.pageY - this.startPosition.pageY);
    // 存储上次
    ev.emit(EventConstants.CAMERA_TOUCH_MOVING, {
      pageX: distanceX,
      pageY: distanceY
    })
    this.startPosition = touch;

  }

  touchEnd = () => {
    this.startPosition = null;
    ev.emit(EventConstants.CAMERA_TOUCH_END)
  }

  componentDidMount() {
    window.addEventListener('orientationchange', this._init, false)
  }

  render() {
    return <div className="camera-touch"
      onTouchMove={this.touchMove}
      onTouchEnd={this.touchEnd}
      onTouchStart={this.touchStart}
      style={{}}
    >
    </div>
  }
}
