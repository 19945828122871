import React, {useEffect} from "react";
import {useInterval, useSetState, useUpdate, useUpdateEffect} from "ahooks";

import './index.scss';

export default function Loading(props) {

  const [state, setState] = useSetState({
    progress: 0,
    hide: true
  })

  useUpdateEffect(() => {
     if(props.visiable) {
       setState({
         hide: false,
         progress: 0
       })
     }
     if(!props.visiable) {
       setState({
         progress: 100
       })
       setTimeout(() => {
         setState({
           hide: true
         })
       }, 2000)
     }
  }, [props.visiable])

  useInterval(() => {
    setState((prev) => {
      const step = Math.ceil(Math.random()*10);
      if(prev.progress + step >= 100) {
        return {}
      }
      return {
        progress: prev.progress + step
      }
    })
  }, 1000)

  return <div className={`loading-wrap ${state.hide ? '' : 'show'}`}>
    <div className={'dis-flex flex-center'} style={{width: '100%', height: '100%'}}>
       <div className={'loading-logo-wrap'}>
          <div className={'loading-logo'}></div>
       </div>
      <div className={'loading-bottom'}>
         <div className={'loading-text'}>
           加载中 {state.progress}%
         </div>
        <div className={'loading-progress'}>
          <div className={'progress-bar'} style={{width: `${state.progress}%`}}>
            <div className={'progress-arrow'}/>
          </div>
        </div>
      </div>
    </div>
  </div>
}
