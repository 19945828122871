import * as BABYLON from 'babylonjs';
import cameraUtils from "@/babylon/utils/cameraUtils";
import TouchingMoving from "@/babylon/utils/TouchingMoving";
import ev, {EventConstants} from "@/babylon/event/eventemitter";

class MainScene {

  constructor(props) {
    this.engine = props?.engine;
    this.container = props?.container;

    // 开启loading
    ev.emit(EventConstants.LOADING_STATUS_CHANGE, true);

    this.scene = new BABYLON.Scene(this.engine);
    this.camera = cameraUtils.createFollowCamera(this.scene, this.container);
    this.camera.rotation = new BABYLON.Vector3(0, -Math.PI / 2, 0);

    this.scene.onBeforeRenderObservable.add(() => {
      if (this.cameraBox) {
        this.cameraBox.position.x = this.camera._position._x;
        this.cameraBox.position.z = this.camera._position._z;
      }
    })

    this._load(this.scene);
    this.light = new BABYLON.HemisphericLight("light", new BABYLON.Vector3(0, 1, 0), this.scene);
    this.light = new BABYLON.HemisphericLight("light", new BABYLON.Vector3(1, 1, 0), this.scene);

    this.scene.gravity = new BABYLON.Vector3(0, -0.1, 0);
    this.scene.collisionsEnabled = true;
    return this.scene;
  }

  _load() {
    const { scene, camera } = this;
    BABYLON.SceneLoader.ImportMesh('', 'https://file.metabookstore.com.cn/vrmodels/h5/main/', 'H5yuanyuzhou5.glb', this.scene, (meshes) => {
      const mesh = meshes[0];
      mesh.scaling = new BABYLON.Vector3(-1.5, 1.5, 1.5);
      // mesh.position = new BABYLON.Vector3(-40, -5, 19); 钟书阁地址
      mesh.position = new BABYLON.Vector3(-45, -1, 21.5);
      mesh.rotation = new BABYLON.Vector3(0, 0, 0);
      ev.emit(EventConstants.LOADING_STATUS_CHANGE, false)
      meshes.forEach((item, i) => {
        if (item.name.includes('dangban') || item.name.includes('Object002')) {
          item.checkCollisions = true;
          item.isVisible = false;
        }
      })
    });


    // BABYLON.SceneLoader.ImportMesh('', 'https://file.metabookstore.com.cn/vrmodels/h5/main/', 'wanshengjienvjuese7.glb', this.scene, (meshes) => {
    //   const mesh = meshes[0];
    //   mesh.scaling = new BABYLON.Vector3(100, 100, 100);
    //   mesh.position = new BABYLON.Vector3(-5.5, -0.9, 1);
 
    // });

    this.cameraBox = BABYLON.MeshBuilder.CreateCylinder("cameraBox", { width: 1, height: 2, depth: 1 });
    this.cameraBox.position.y = -1;

    // 办公大厦坐标 (-5.5, -0.9, 1)
    BABYLON.SceneLoader.ImportMesh('', 'https://file.metabookstore.com.cn/vrmodels/h5/main/', 'chuansongdian.glb', this.scene, (meshes) => {
      const mesh = meshes[0];
      mesh.position = new BABYLON.Vector3(-5.5, -0.9, 1);
      mesh.actionManager = new BABYLON.ActionManager(this.scene);
      mesh.material = new BABYLON.StandardMaterial("mansionHitBox", this.scene);
      mesh.actionManager.registerAction(new BABYLON.ExecuteCodeAction(
        { trigger: BABYLON.ActionManager.OnIntersectionEnterTrigger, parameter: this.cameraBox },
        function () {
          ev.emit(EventConstants.GUIDE_STATUS_CHANGE, 'MANSION_C');
        }
      ));
    });

    // 商城坐标 (6, -1, 1)

    // NPC坐标 (-9, -1, -6)

    BABYLON.SceneLoader.ImportMesh('', 'https://file.metabookstore.com.cn/vrmodels/h5/main/', 'chuansongdian.glb', this.scene, (meshes) => {
      const mesh = meshes[0];
      mesh.position = new BABYLON.Vector3(-9, -0.9, -6);
      mesh.actionManager = new BABYLON.ActionManager(this.scene);
      mesh.material = new BABYLON.StandardMaterial("NPCHitBox", this.scene);
      mesh.actionManager.registerAction(new BABYLON.ExecuteCodeAction(
        { trigger: BABYLON.ActionManager.OnIntersectionEnterTrigger, parameter: this.cameraBox },
        function () {
          ev.emit(EventConstants.GUIDE_STATUS_CHANGE, 'NPC_C');
        }
      ));
    });


    // 高级住宅区坐标 (-12, -1, -16)

    BABYLON.SceneLoader.ImportMesh('', 'https://file.metabookstore.com.cn/vrmodels/h5/main/', 'chuansongdian.glb', this.scene, (meshes) => {
      const mesh = meshes[0];
      mesh.position = new BABYLON.Vector3(-12, -0.9, -16);
      mesh.actionManager = new BABYLON.ActionManager(this.scene);
      mesh.material = new BABYLON.StandardMaterial("uptownHitBox", this.scene);
      mesh.actionManager.registerAction(new BABYLON.ExecuteCodeAction(
        { trigger: BABYLON.ActionManager.OnIntersectionEnterTrigger, parameter: this.cameraBox },
        function () {
          ev.emit(EventConstants.GUIDE_STATUS_CHANGE, 'UPTOWN_C');
        }
      ));
    });

    // 普通住宅坐标 (-4, -1, -24)

    BABYLON.SceneLoader.ImportMesh('', 'https://file.metabookstore.com.cn/vrmodels/h5/main/', 'chuansongdian.glb', this.scene, (meshes) => {
      const mesh = meshes[0];
      mesh.position = new BABYLON.Vector3(-4, -0.9, -24);
      mesh.actionManager = new BABYLON.ActionManager(this.scene);
      mesh.material = new BABYLON.StandardMaterial("townHitBox", this.scene);
      mesh.actionManager.registerAction(new BABYLON.ExecuteCodeAction(
        { trigger: BABYLON.ActionManager.OnIntersectionEnterTrigger, parameter: this.cameraBox },
        function () {
          ev.emit(EventConstants.GUIDE_STATUS_CHANGE, 'TOWN_C');
        }
      ));
    });


    // 机场坐标 (-18, -1, -27)

    BABYLON.SceneLoader.ImportMesh('', 'https://file.metabookstore.com.cn/vrmodels/h5/main/', 'chuansongdian.glb', this.scene, (meshes) => {
      const mesh = meshes[0];
      mesh.position = new BABYLON.Vector3(-18, -0.9, -27);
      mesh.actionManager = new BABYLON.ActionManager(this.scene);
      mesh.material = new BABYLON.StandardMaterial("airplaneHitBox", this.scene);
      mesh.actionManager.registerAction(new BABYLON.ExecuteCodeAction(
        { trigger: BABYLON.ActionManager.OnIntersectionEnterTrigger, parameter: this.cameraBox },
        function () {
          ev.emit(EventConstants.SCENE_CHANGE, 'ISLAND');
        }
      ));
    });




    //Set gravity for the scene (G force like, on Y-axis)
    scene.gravity = new BABYLON.Vector3(0, -0.9, 0);

    // Enable Collisions
    scene.collisionsEnabled = true;

    //Then apply collisions and gravity to the active camera
    camera.checkCollisions = true;
    // camera.applyGravity = true; //重力

    //Set the ellipsoid around the camera (e.g. your player's size)
    camera.ellipsoid = new BABYLON.Vector3(1, 1, 1);


    new TouchingMoving(this.camera, this.engine, {
      speed: 0.005,
      startMoving: (params) => {
        // this.scene.beginAnimation(result.skeletons[0], 0, 100, true, 1.0);
        // console.log(this.camera.rotationQuaternion, 'this.camera.rotationQuaternion++++++++++++++++++');
        // console.log(this.camera.cameraDirection, this.camera.cameraRotation, this.camera._cameraRotationMatrix);
      },
      stopMoving: (params) => {
        // this.scene.stopAnimation(result.skeletons[0]);
      }
    });

  }

}

export default MainScene;
