class LoadingScene {
  constructor(props) {
    const {text} = props;

    this.loadingUIText = text;
  }

  displayLoadingUI = () => {
    console.log(this.loadingUIText);
  }

  hideLoadingUI = () => {
     console.log('loaded');
  }

}

export default LoadingScene;
