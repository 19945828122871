import React, { useEffect } from "react";
import { useSetState, useRequest } from "ahooks";
import { GAME_SCENES } from "@/babylon/scenes";
import readerApi from '@/shared/api/readerApi';
import * as localStorage from '@/shared/utils/storage';

import './index.scss';
import LocationPanel from "./LocationPanel";
import RewardPanel from "./RewardPanel";
import { update } from "react-spring";
import GuidePanel from "./GuidePanel";
import IslandGuidePanel from "./IslandGuidePanel";

import { Toast } from "antd-mobile";
import MessagePanel from "./MessagePanel";
import VisitPanel from "./VisitPanel";
import ev, {EventConstants} from "@/babylon/event/eventemitter";
import classNames from "classnames";

export default function Set(props) {
  const { data: mySkyCityNftNumber } = useRequest(readerApi.getMySkyCityNftNumber);

  const [state, setState] = useSetState({
    scenesState: 'main',
    panelState: 0,
    panelGuideType: '',
    activeCityNum: '',
    activeLocation: 'main',
    isOwn: 0,
    isSignIn: 0,
    nftId: 1,
    mySkyCityNftNumberPage: [] // 轮播图分页数
  })
  const { scenesState } = state;

  const checkScens = (scen, activeCityNum) => {
    props.checkScens(scen === 'main' ? GAME_SCENES.MAIN : GAME_SCENES.ISLAND, activeCityNum);
    setState({
      activeCityNum,
      scenesState: scen
    })
  }

  useEffect(() => {
    if (mySkyCityNftNumber?.length) {
      const mySkyCityNftNumberPage = [];
      const length = Math.ceil(mySkyCityNftNumber.length / 12);
      for (let i = 0; i < length; i++) {
        mySkyCityNftNumberPage.push(i + 1);
      }
      setState({ mySkyCityNftNumberPage });

      // setState({ activeCityNum: mySkyCityNftNumber[0], activeLocation: 'myIsland', mySkyCityNftNumberPage });
    } else if (Array.isArray(mySkyCityNftNumber)) {
      // const randomNum = Math.floor(Math.random() * 480 + 1);
      // setState({ activeCityNum: randomNum, activeLocation: 'otherIsland' });
    }

  }, [mySkyCityNftNumber])

  useEffect(() => {
    if (state.scenesState === 'island' && !localStorage.get('HAD_VISITED_ISLAND')) {
      setTimeout(() => {
        setState({
          panelState: 'islandGuid'
        })
      }, 2000);

    }

  }, [state.scenesState])

  const updateRewordData = (nftNumber) => {
    if (nftNumber) {
      readerApi.getSkyCityByNftNumber({ nftNumber }).then(({ isOwn, isSignIn, nftId, userName, avatar }) => {
        setState({
          isOwn,
          isSignIn,
          nftId,
          userName,
          avatar
        })
      })
    }
  }

  useEffect(() => {
    updateRewordData(state.activeCityNum);

  }, [state.activeCityNum])

  useEffect(() => {
    ev.on(EventConstants.GUIDE_STATUS_CHANGE, (panelGuideType) => {
      setState({
        panelState: 'panelGuid',
        panelGuideType
      })
    })

    ev.on(EventConstants.SCENE_CHANGE, (scene) => {
      if(scene === 'ISLAND'){
        setState({
          panelState: 'locationPanel'
        })
      }
    })
  }, [])


  const { activeCityNum, activeLocation, isSignIn, isOwn, nftId, panelState, userName, avatar, mySkyCityNftNumberPage, panelGuideType} = state;

  return (
    <>
      <div className={classNames('setGroup', scenesState === 'island' && 'purpleSetGroup')}>
        <div className="setItem" onClick={() => {
          setTimeout(() => {
            setState({
              panelState: 'locationPanel'
            })
          }, 1000)
        }}>
          <div className="setIcon">
            <div className="islandIcon" />
          </div>
          <div>起航</div>
        </div>

        {/* <div className="setItem" onClick={() => {
        }}>
          <div className="setIcon">
            <div className="userIcon" />
          </div>
          <div>个人中心</div>
        </div> */}
        {scenesState === 'island' && <div className="setItem" onClick={() => {
          setState({
            panelState: 'visitPanel'
          })
        }}>
          <div className="setIcon">
            <div className="visitIcon" />
          </div>
          <div>访客记录</div>
        </div>}
        {scenesState === 'island' &&
          <div className="setItem" onClick={() => {
            setState({
              panelState: 'messagePanel'
            })
          }}>
            <div className="setIcon">
              <div className="clockIcon" />
            </div>
            <div>打卡留言</div>
          </div>}
        {scenesState === 'island' && <div className="setItem" onClick={() => {
          setState({
            panelState: 'rewardPanel'
          })
        }}>
          <div className="setIcon">
            <div className="rewardIcon" />
          </div>
          <div>万圣节福利</div>
        </div>
        }

      </div>

      {activeCityNum && scenesState === 'island' && <div className="cityInfo">
        <img src={avatar || DEFAULT_URL} />
        <div>
          <div><span>城&nbsp;&nbsp;&nbsp;主：</span><span>{userName || '--'}</span></div>
          <div><span>门牌号：</span><span># {activeCityNum}</span></div>
        </div>
      </div>}
      <div className="panelGroup">
        {
          panelState === 'islandGuid' &&
          <IslandGuidePanel onClose={() => {
          localStorage.put('HAD_VISITED_ISLAND', 1)
          setState({
            panelState: 0
          })
        }} />}
        {
          panelState === 'panelGuid' && panelGuideType &&
          <GuidePanel panelGuideType={panelGuideType} onClose={() => {
            setState({
              panelState: 0,
              panelGuideType: ''
            })
        }} />}
        {!!nftId &&
          <VisitPanel
            visibel={panelState === 'visitPanel'}
            nftId={nftId}
            onClose={() => {
              setState({
                panelState: 0
              })
            }} />}
        {!!nftId &&
          <MessagePanel
            visibel={panelState === 'messagePanel'}
            nftId={nftId}
            onClose={() => {
              setState({
                panelState: 0
              })
            }} />}
        {
          // activeCityNum !== '' &&
          <LocationPanel
            checkScens={checkScens}
            mySkyCityNftNumber={mySkyCityNftNumber}
            mySkyCityNftNumberPage={mySkyCityNftNumberPage}
            activeCityNum={activeCityNum}
            activeLocation={activeLocation}
            visibel={panelState === 'locationPanel'}
            scenesState={scenesState}
            onClose={() => {
              setState({ panelState: 0 })
            }} />}
        {
          activeCityNum &&
          <RewardPanel
            mySkyCityNftNumber={mySkyCityNftNumber || 1}
            {...state}
            visibel={panelState === 'rewardPanel'}
            isSignIn={isSignIn}
            isOwn={isOwn}
            nftId={nftId}
            updateRewordData={updateRewordData}
            onClose={() => {
              setState({ panelState: 0 })
            }} />}
      </div>

    </>);

}

const DEFAULT_URL = 'https://oss.raysgo.com/oss/uploadfe/jpg/904f0ac1567aa96f68a87adf7a5b2008.jpg';
