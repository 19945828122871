//验证数据是否不为空（空值时返回false，null、undefined、空字符串、空数组、空对象都被设定为空）
export const isNotEmpty = (value) => {
  switch (typeof (value)) {
    case 'undefined': {
      return false;
    }

    case 'string': {
      return value.length !== 0;
    }

    case 'object': {
      if(Array.isArray(value)) {
        return value.length !== 0;
      } else if(value === null) {
        return false;
      } else {
        return Object.keys(value).length !== 0;
      }
    }

    default: {
      return true;
    }
  }
};