import React, { useEffect } from "react";
import { CloseOutline } from 'antd-mobile-icons';
import readerApi from '@/shared/api/readerApi';
import { useSetState } from "ahooks";
import { Empty } from "antd-mobile";
import dayjs from "dayjs";

import './VisitPanel.scss';

export default function VisitPanel(props) {

  const { nftId, visibel } = props;

  const [state, setState] = useSetState({
    visitList: [],
  })

  const onClose = ()=>{
    props.onClose();
  }

  const getLeaveMessage = ()=>{
    readerApi.getVisitorRecords({ nftId, currentPage: 1, numPerPage: 50 }).then((data) => {
      if(Array.isArray(data.recordList)){
        setState({
          visitList: data.recordList
        })
      }

    }).catch(({})=>{

    })
  }


  useEffect(()=>{
    if(nftId){
      getLeaveMessage()
    }
  }, [nftId])


  return (visibel && <div className="VisitPanel">
  <div className="VisitPanelBg">
      <div className="top">
        <span>访客记录</span>
        <CloseOutline onClick={onClose}/>
      </div>
      <div className="content">
        <div className="headInfo">
          <div className="userName">访客名称</div>
          <div className="intimary"></div>
          <div className="time">来访时间</div>
        </div>
        {state.visitList?.length ?
          <div className="visitList">
            {state.visitList.map(({ userName, intimary, updateTime, secretPhone }) => (
              <div className="visitItem">
                  <div className="userName">{userName || secretPhone || '--'}</div>
                  <div className="intimary"></div>
                  <div className="time">{dayjs(updateTime).format('MM-DD HH:mm')}</div>
              </div>
            ))}
          </div>
          :
          <Empty description='暂无访客哦，邀请好友来逛逛吧~' className="empty" />}
      </div>
    </div>

  </div>)
}
