import * as BABYLON from "babylonjs";

import ev, {EventConstants} from "@/babylon/event/eventemitter";

export default {
  createFollowCamera(scene, container) {
    const camera = new BABYLON.FreeCamera("FreeCamera", new BABYLON.Vector3(0, 0.3, 0.3), scene);
    camera.attachControl(container, true);

    // 不能看到头顶
    camera.lowerBetaLimit = Math.PI/3
    // // 这个生效了，现在摄像机不能看到头顶了
    camera.upperBetaLimit = Math.PI/2



    //First remove the default management.
    //
    // //Add the new mouse input manager to the camera
    // camera.inputs.add(new FreeCameraSearchInput());
    // camera.inputs.attached.keydown.detachControl();
    // camera.lowerRadiusLimit=1;
    // 最小缩放
    // camera.upperRadiusLimit=1;
    // camera.angle = Math.PI/2;
    // camera.direction = new BABYLON.Vector3(Math.cos(camera.angle), 0, Math.sin(camera.angle));
    return camera;
  }
}
