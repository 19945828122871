
const JSUtils =  {
  /**
   * 节流函数，在指定周期内最多只执行一次函数
   * @param func<Function>: 要节流的函数
   * @param interval<Number>: 节流周期
   */
  throttle(func, interval = 500) {
    let last = 0;
    let timer = null;
    return function(...args) {
      const now = Date.now();
      const diff = now - last;

      const execute = () => {
        last = now;
        func(...args);
      };

      if(diff >= interval) {
        window.clearTimeout(timer);
        execute();
      } else {
        window.clearTimeout(timer);
        timer = window.setTimeout(() => {
          timer = null;
          execute();
        }, interval - diff);
      }
    };
  },
  debounce(func, interval = 100) {
    let last = 0;
     return function (...args) {
       const now = Date.now();
       const diff = now - last;
       if(diff >= interval) {
         last = now;
         func(...args);
       }
     }
  }
}

export default JSUtils;
