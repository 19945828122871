import React from "react";
import { CloseOutline } from 'antd-mobile-icons';

import './GuidePanel.scss';
import classNames from "classnames";

export default function GuidePanel(props) {

  const { onClose, panelGuideType } = props;

  return <div className={classNames('GuidePanel', panelGuideType.includes('_P') && 'PurpleGuidePanel')} onClick={onClose}>
   { !panelGuideType.includes('_C') && <div className="GuidePanelBg">
      <div className="content">
        <div className="top">
        欢迎来到天空之城！
        </div>
        <div className="middle">
        天空之城作为沐然星元宇宙创世贵族的空中城堡，是创世主最顶配的私人住宅，城主将开放元宇宙专属个人藏品室。

        </div>
        <div className="bottom">
          <span className="rightIcon" />
        欢迎参观
        </div>

      </div>
      <div className="person">
        <img src="https://oss.5rs.me/oss/uploadfe/png/79f3329616b30658bad7c1278983bf53.png" />
      </div>


    </div>}

    {panelGuideType.includes('_C') && <div className="centerGuidePanelBg">
      <div
        className="close"
        onClick={onClose}>

          <CloseOutline />
        </div>
        <div className="title">
       {GUIDE_STATUS[panelGuideType].name}
        </div>
        <div className="subTitle">
       {GUIDE_STATUS[panelGuideType].subTitle}
        </div>
        <div className="content">
        {GUIDE_STATUS[panelGuideType].content}
        </div>


    </div>}
  </div>
}


const GUIDE_STATUS = {
  MANSION_C: {
    name: '沐然星元字宙中心大厦',
    subTitle: '暂未开放',
    content: '沐然星元宇宙最强大的综合配套区域，经营成一个集合写字楼、公寓、商住、星级酒店、会展中心、休闲娱乐及购物中心于一体的大空间。为大家在元宇宙中，获得更高经济收入的机会:创造数字资产、获得专属NFT道具，拥有属于自己的元宇宙空间。'
  },
  UPTOWN_C: {
    name: '高级住宅区',
    subTitle: '暂未开放',
    content: '别墅多起来的时候，一个庄园和另外一个庄园联起来就成了庄园区，这是别墅区的原形。别墅与普通住宅相比，除了基本功能相似之外还有很多不同之处，别墅是一种带有诗意的住宅，它代表着人类的某种理想。是用来享受生活的居所'
  },
  TOWN_C:{
    name: '普通住宅区',
    subTitle: '暂未开放',
    content: '按所在地一般民用住宅建筑标准建造的居住用房屋。是现代城镇人们居住的一种模式，被城市道路或自然分界线所围合，并与居住人口规模相适应(10000—15000人)，建有能满足该区居民基本物质文化生活，所需的公共服务设施的居住生活聚居地。'
  },
  NPC_C:{
    name: 'NPC住宅区',
    subTitle: '暂未开放',
    content: '平台为NPC数字人提供的住宅基地，沐然星元宇宙NPC土著数字人，将为商圈及商家带来第一波流量和营收。'
  },
  MALL_C:{
    name: '商场',
    subTitle: '暂未开放',
    content: '沐然星元宇宙最强大的综合配套区域，经营成一个集合写字楼、公寓、商住、星级酒店、会展中心、休闲娱乐及购物中心于一体的大空间。为大家在元宇宙中，获得更高经济收入的机会'
  },
  
  MONV_C_P:{
    name: '飞天魔女',
    subTitle: '',
    content: <div>
      <div>没有人知道飞天魔女的真名叫什么，只知道万圣节期间她会在创世城的街道和天空之城出没，
    戴着稍显夸张的南瓜帽和可以让她自由飞翔的飞天斗篷，
    骑着她的玫瑰扫帚穿梭在各个天空之城，留下她最喜欢的南瓜派~</div>
      <div>魔女很爱凑热闹，但是天空之城可以跟她一起玩的小鬼并不多，
    大家碰到她一定要和她去打招呼哦，她会很开心的。</div>
      <div> “要尝尝我做的南瓜派吗？” </div>
      <div>“吃了南瓜派的小孩子要陪我过万圣节哟~”</div>
 </div>
  },

}

